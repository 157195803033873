import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled from "styled-components";
import SwiperCore, {
  Autoplay,
  Grid,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";

// 카테고리 개별 구분

const CateogryMobileWrapper = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const CategoryMobile = styled.div`
  display: flex;
  .swiper {
    padding-bottom: 50px;
  }
  .swiper-scrollbar-horizontal {
    display: flex;
    align-items: center;
    height: 3px;
    width: 80%;
    left: 0;
    bottom: 12px;
  }
  @media screen and (max-width: 430px) {
    .swiper-pagination-fraction {
      font-size: 12px;
    }
  }
`;

const MobileSliderArrows = styled.div`
  left: 80%;
  width: 20%;
  bottom: 0px;
  padding: 0px 5px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  z-index: 30;
  & span {
    cursor: pointer;
    :hover {
      color: #3bb77e;
    }
  }
  color: #000000;
  @media screen and (max-width: 430px) {
    font-size: 26px;
  }
`;

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
  @media screen and (max-width: 768px) {
    gap: 25px;
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 575px) {
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const CategoryType = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  margin-bottom: 25px;
`;

const TypeBtn = styled.button`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: ${(props) => (props.categoryType ? "#3bb77e" : "#f1f1f1")};

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: normal;
    text-align: start;
    color: ${(props) => (props.categoryType ? "#fff" : "#565656")};
    & .question {
      font-size: 14px;
    }
    & h6 {
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      color: ${(props) => (props.categoryType ? "#fff" : "#565656")};
    }
    .m_title {
      display: none;
      & h6 {
        margin-bottom: 3px;
      }
    }
  }
  .icon {
    font-size: 40px;
    line-height: normal;
    color: ${(props) => (props.categoryType ? "#fff" : "#565656")};
    & i {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 991px) {
    padding: 20px 20px;
    .title {
      & .question {
        font-size: 12px;
      }
      & h6 {
        font-size: 18px;
      }
    }
    .icon {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    .title {
      & .question {
        font-size: 10px;
      }
      & h6 {
        font-size: 16px;
      }
    }
    .icon {
      font-size: 28px;
      display: none;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 15px;
    .title {
      & .question {
        display: none;
      }
      & h6 {
        font-size: 16px;
      }
      .pc_title {
        display: none;
      }
      .m_title {
        display: flex;
        flex-direction: column;
      }
    }
    .icon {
      font-size: 28px;
      display: block;
    }
  }
`;

const CategoryListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CategoryList = styled.div`
  display: flex;
  position: relative;
`;

const CategoryBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  & figure {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: #f4f4f4;
    padding: 10px;
    border: ${(props) => (props.active ? "3px solid #3bb77e" : "none")};

    @media screen and (min-width: 1200px) {
      width: 115px;
      height: 115px;
    }
    @media screen and (max-width: 768px) {
      min-width: 58px;
      min-height: 58px;
      max-width: 65px;
      max-height: 65px;
      border-radius: 15px;
    }
    @media screen and (max-width: 430px) {
      width: 45px;
      height: 45px;
      border-radius: 15px;
    }
  }
  & img {
    width: 100%;
  }
  :hover {
    & span {
      color: #3bb77e;
    }
  }
`;

const CategorySliderName = styled.span`
  width: 100%;
  text-align: center;
  word-break: keep-all;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const SlideArrows = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 25%;
  left: -40px;
  width: calc(100% + 80px);

  & span {
    top: 25%;
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    box-shadow: 2px 3px 6px 0px rgba(105, 105, 105, 0.25);
    background-color: #fff;
    color: #5a5a5a;
    font-size: 19px;
    cursor: pointer;
    z-index: 9;
    :hover {
      background-color: #3bb77e;
      color: #fff;
    }
  }
  .custom_next_ct1 {
    right: 0;
  }
`;

SwiperCore.use([Navigation, Autoplay, Scrollbar, Pagination, Grid]);

const CategorySlider = ({ dummyList }) => {
  const router = useRouter();
  const [category, setCategory] = useState([]);
  const [categoryType, setCategoryType] = useState("S");
  const [pcSwiper, setPcSwiper] = useState();

  const handleCategoryType = (type) => {
    setCategoryType(type);
  };

  const selectCategory = (e, category) => {
    if (category.code === "custom") {
      router.push("/customizing/customizing-products/");
      return;
    }
    e.preventDefault();
    router.push({
      pathname: "/products",
      query: {
        code: category.code,
        type: categoryType,
      },
    });
  };

  const fetchCategory = async () => {
    setCategory([
      {
        code: "custom",
        name: "맞춤제작",
        image:
          "https://file.caretbio.com/caretbio/6d02e18cf31baf6a36c451db02e27461.png",
      },
      ...dummyList.category?.filter((el) =>
        categoryType === "T" ? el.division === "T" : el.division === "S"
      ),
    ]);
  };

  useEffect(() => {
    if (dummyList.category) {
      fetchCategory();
    }
  }, [dummyList, categoryType]);

  useEffect(() => {
    if (router.query.type) {
      setCategoryType(router.query.type);
    }
  }, [router.query]);

  return (
    <>
      {category && (
        <>
          {/* mobile */}
          {!router.pathname.includes("products") && (
            <CateogryMobileWrapper>
              <CategoryType>
                <TypeBtn
                  categoryType={!categoryType || categoryType === "S"}
                  value={"S"}
                  onClick={(e) => handleCategoryType("S")}
                >
                  <div className="title">
                    <span className="question">
                      원하시는 포장재가 있으신가요?
                    </span>
                    <h6 className="pc_title">포장재 먼저 선택하기</h6>
                    <div className="m_title">
                      <h6>포장재</h6>
                      <span>먼저 선택하기</span>
                    </div>
                  </div>
                  <div className="icon">
                    <i className="fi-rs-check-circle" />
                  </div>
                </TypeBtn>
                <TypeBtn
                  categoryType={categoryType === "T"}
                  value={"T"}
                  onClick={(e) => handleCategoryType("T")}
                >
                  <div className="title">
                    <span className="question">
                      어떤 포장재를 골라야 될지 어려우신가요?
                    </span>
                    <h6 className="pc_title">포장 할 상품을 먼저 선택하기</h6>
                    <div className="m_title">
                      <h6>포장 할 상품</h6>
                      <span>먼저 선택하기</span>
                    </div>
                  </div>
                  <div className="icon">
                    <i className="fi-rs-check-circle" />
                  </div>
                </TypeBtn>
              </CategoryType>
              <CategoryMobile>
                <Swiper
                  navigation={{
                    prevEl: ".custom_prev_mc",
                    nextEl: ".custom_next_mc",
                  }}
                  className=""
                  spaceBetween={30}
                  slidesPerView={1}
                  initialSlide={0}
                  scrollbar={{
                    hide: false,
                  }}
                  // pagination={{
                  //   type: "fraction",
                  // }}
                >
                  {category &&
                    category.length > 0 &&
                    new Array(Math.ceil(category.length / 8))
                      .fill("")
                      .map((_, idx) => idx + 1)
                      .map((el, gridIdx) => (
                        <SwiperSlide key={gridIdx}>
                          <CategoryGrid>
                            {category
                              .slice(el * 8 - 8, el * 8)
                              ?.map((item, i) => (
                                <CategoryBox
                                  onClick={(e) => selectCategory(e, item)}
                                  key={i}
                                >
                                  <figure className="">
                                    <img src={item.image} alt="" />
                                  </figure>
                                  <h6>
                                    <CategorySliderName>
                                      {item.name}
                                    </CategorySliderName>
                                  </h6>
                                </CategoryBox>
                              ))}
                          </CategoryGrid>
                        </SwiperSlide>
                      ))}
                  <MobileSliderArrows>
                    <span className="custom_prev_mc">
                      <i className="fi-rs-arrow-small-left"></i>
                    </span>
                    <span className="custom_next_mc">
                      <i className="fi-rs-arrow-small-right"></i>
                    </span>
                  </MobileSliderArrows>
                </Swiper>
              </CategoryMobile>
            </CateogryMobileWrapper>
          )}
          {/* pc */}
          <CategoryListWrapper>
            <CategoryType>
              <TypeBtn
                categoryType={!categoryType || categoryType === "S"}
                value={"S"}
                onClick={(e) => handleCategoryType("S")}
              >
                <div className="title">
                  <span>원하시는 포장재가 있으신가요?</span>
                  <h6>포장재 먼저 선택하기</h6>
                </div>
                <div className="icon">
                  <i className="fi-rs-check-circle" />
                </div>
              </TypeBtn>
              <TypeBtn
                categoryType={categoryType === "T"}
                value={"T"}
                onClick={(e) => handleCategoryType("T")}
              >
                <div className="title">
                  <span>어떤 포장재를 골라야 될지 어려우신가요?</span>
                  <h6>포장 할 상품을 먼저 선택하기</h6>
                </div>
                <div className="icon">
                  <i className="fi-rs-check-circle" />
                </div>
              </TypeBtn>
            </CategoryType>
            <CategoryList>
              <Swiper
                navigation={{
                  prevEl: ".custom_prev_ct1",
                  nextEl: ".custom_next_ct1",
                }}
                className=""
                centeredSlides={false}
                spaceBetween={30}
                slidesPerView={8}
                slidesPerGroupSkip={0}
                breakpoints={{
                  768: {
                    slidesPerView: 8,
                    slidesPerGroup: 8,
                  },
                }}
                grabCursor={true}
                initialSlide={0}
                onSwiper={setPcSwiper}
                onSlideChange={(swiper) =>
                  setPcSwiper({ ...pcSwiper, realIndex: swiper.realIndex })
                }
              >
                {category?.map((item, i) => (
                  <SwiperSlide key={i}>
                    <CategoryBox
                      onClick={(e) => selectCategory(e, item)}
                      active={router.query.code === item.code}
                    >
                      <figure className="">
                        <img src={item.image} alt="" />
                      </figure>
                      <h6>
                        <CategorySliderName>{item.name}</CategorySliderName>
                      </h6>
                    </CategoryBox>
                  </SwiperSlide>
                ))}
              </Swiper>
              <SlideArrows className="slider-arrow">
                <span
                  className="custom_prev_ct1"
                  style={{
                    visibility:
                      pcSwiper && pcSwiper?.realIndex == 0
                        ? "hidden"
                        : "visible",
                  }}
                >
                  <i className="fi-rs-angle-left"></i>
                </span>
                <span
                  className="custom_next_ct1"
                  style={{
                    visibility:
                      pcSwiper && pcSwiper?.realIndex + 8 >= category?.length
                        ? "hidden"
                        : "visible",
                  }}
                >
                  <i className="fi-rs-angle-right"></i>
                </span>
              </SlideArrows>
            </CategoryList>
          </CategoryListWrapper>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  dummyList: state.dummyList,
});

export default connect(mapStateToProps, null)(CategorySlider);
